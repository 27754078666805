<template>
  <div class="history-container">
    <el-card v-for="(item, index) in data" :key="index" class="history-card">
      <div class="history-content">
        <div class="history-left">
          <h1>{{ type === 0 ? "学习" : "口语" }}计划{{ item.planId }}</h1>
          <p>{{ item.createDate }}</p>
        </div>
        <div @click="handleDetail(item.planId)" class="history-right">
          <span :class="item.status === 1 ? 'finish' : 'lose'">
            {{
              historyStatus(item.status)
            }}
          </span>
          <img src="@/assets/img/writing/next@3x.png" class="cs-chevronright" />
        </div>
      </div>
    </el-card>
    <div class="bottom-area">
      <hcp-pagination v-if="isPage" @changePage="changePage" :total="total" />
    </div>
  </div>
</template>

<script>
import HcpPagination from "@/components/HcpPagination.vue";

export default {
  components: { HcpPagination },
  props: {
    data: Array,
    isPage: {
      type: Boolean,
      default: true,
    },
    total: {
      type: Number,
      default: 0,
    },
    type: Number,
  },
  data() {
    return {};
  },
  methods: {
    changePage(page) {
      this.$emit("changePage", page);
    },
    historyStatus(val) {
      switch (val) {
        case 0:
          return "未完成";
        case 1:
          return "已完成";
        case 2:
          return "已失效";
      }
    },
    handleDetail(val) {
      let path = "";
      if (this.type === 0) {
        path = "/writing";
      } else if (this.type === 1) {
        path = "/oral";
      } else {
        return false;
      }
      this.$router.push({
        path: path,
        query: { type: "plan", planId: val, history: true },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.history-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .history-card {
    width: 467px;
    height: 89px;
    background: #ffffff;
    border-radius: 16px;
    margin-bottom: 18px;
    .history-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .history-left {
        h1 {
          font-size: 16px;
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          color: #333643;
        }
        p {
          margin-top: 9px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #83888c;
        }
      }
      .history-right {
        cursor: pointer;
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
        }
        .finish {
          color: #2fc049;
        }
        .lose {
          color: #8e95a3;
        }
        .cs-chevronright {
          width: 24px;
          height: 24px;
          transform: translateY(-1px);
        }
      }
    }
  }
  .bottom-area {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}
</style>