<template>
  <div>
    <div class="popContainer" :style="{
      display: $route.query.type === 'plan' && popClose ? (
        isWriting() ? 'block' : 'none'
      ) : 'none',
    }"></div>


    <div class="calendar_box">
      <PlanCalendarTitle :allSection="allSection" :section="section" :desc="desc" :allFinishStatus="allFinishStatus"
        :finishStatus="finishStatus" :period="period" :type="type" :firPeriod="firPeriod" :secPeriod="secPeriod"
        :thPeriod="thPeriod" :fouPeriod="fouPeriod" :topTips="topTips" :finish="finish" :total="total" />
        
      <div class="calendar_month">
        <img v-if="
          !(
            new Date(planStartDate).getFullYear() === currentYear &&
            new Date(planStartDate).getMonth() + 1 === currentMonth
          )
        " src="@/assets/img/writing/pre@3x.png" class="cs-chevronleft" @click="pre()" />
        <span>{{ currentYear + "年" + currentMonth + "月" }}</span>
        <img v-if="
          !(
            new Date(examDate).getFullYear() === currentYear &&
            new Date(examDate).getMonth() + 1 === currentMonth
          )
        " src="@/assets/img/writing/next@3x.png" class="cs-chevronright" @click="next()" />
      </div>
      <div class="calendar_week">
        <div class="cal-cell1">日</div>
        <div class="cal-cell1">一</div>
        <div class="cal-cell1">二</div>
        <div class="cal-cell1">三</div>
        <div class="cal-cell1">四</div>
        <div class="cal-cell1">五</div>
        <div class="cal-cell1">六</div>
      </div>
      <div class="calendar_days">
        <div class="calendar_week_days" v-for="(day, index) in days" :key="index">
          <div class="cal_month_day" :style="{
            zIndex: item.mock && popClose && isWriting() ? '2003' : '0',
            background: item.mock && popClose && isWriting() ? '#FFF5DD' : '',
          }" :class="{
  advance: item.advance ? true : false,
  prepare: item.prepare ? true : false,
  spring: item.spring ? true : false,
  warm: item.warm ? true : false,
}" v-for="(item, idx) in day" :key="idx">
            <span :class="{
              active:
                (currentDayValue === item.value && !popClose) ||
                (item.mock && popClose),
            }">
              {{
                  (currentDayValue === item.value && !popClose) ||
                    (item.mock && popClose)
                    ? "今"
                    : ""
              }}
            </span>
            <span :class="{
              examDateDate: examDate === item.value2 && !popClose,
              disabled: item.disabled && !item.lastThreeDay,
              lastThree: item.lastThreeDay,
            }">{{ item.day }}</span>
            <div v-if="examDate === item.value2 && !popClose" class="examDate">考试日</div>
            <template v-if="item.mock === true && popClose && type === 0">
              <!-- <p>{{ type === 0 ? "政府 文化" : "1节" }}</p> -->
              <p>政府 文化</p>
              <div class="btn-container">
                <div @click="handleKnow" class="selected-btn">我知道了</div>
                <div @click="handleNoTips" class="close-btn">不再提示</div>
                <img class="img-pcdianji" src="@/assets/img/writing/pcdianji@3x.png" alt="pcdianji" />
                <p class="text-pcdianji">
                  {{ type === 0 ? "示例：查看当日任务" : "示例：点击去约课" }}
                </p>
                <img v-if="!tipsShow" @click="handleNoTips" class="img-btn_xz" src="@/assets/img/writing/btn_wxz@3x.png"
                  alt="btn_wxz" />
                <img v-else @click="handleNoTips" class="img-btn_xz" src="@/assets/img/writing/btn_xz@3x.png"
                  alt="btn_xz" />
              </div>
            </template>
            <template v-if="type === 0">
              <div v-for="(ex, i) in events" :key="i" @click="ex.planDate == item.value ? toEventDetail(ex) : ''"
                class="topic-name" :class="
                  ex.planDate == item.value && !popClose
                    ? 'topic-name-area'
                    : ''
                ">
                <p v-for="(item2, index2) in ex.topicNameList" :key="index2">
                  {{ ex.planDate == item.value ? item2 : "" }}
                </p>

                <img v-if="ex.planDate == item.value && ex.status" class="img-finish"
                  src="@/assets/img/writing/finish_logo@3x.png" alt="tip" />
              </div>
            </template>

            <template v-if="type === 1">
              <!-- 这个click是要删除的代码 -->
              <!-- @click="ex.planDate == item.value ? toEventDetail(ex) : ''" -->
              <div v-for="(ex, i) in events" :key="i" class="topic-name" :class="
                ex.planDate == item.value && !popClose
                  ? 'topic-name-area'
                  : ''
              ">
                <template v-if="ex.topicNameList">
                  <!-- <p v-for="(item2, index2) in ex.topicNameList" :key="index2">
                    {{ ex.planDate == item.value ? item2 : "" }}
                  </p> -->
                  <PlanCalendarExtra :practiceNum="ex.practiceNum" :distributeNum="ex.distributeNum"
                    :topicNameList="ex.topicNameList" :planDate="ex.planDate" :value="item.value" />
                </template>
                <p v-else>
                  {{ ex.planDate == item.value ? `${ex.distributeNum}节` : "" }}
                </p>
                <!-- <img v-if="ex.planDate == item.value && ex.status" class="img-finish"
                  src="@/assets/img/writing/finish_logo@3x.png" alt="tip" /> -->
                <!-- <span class="section-finish" v-else-if="
                  ex.planDate == item.value
                    ? ex.distributeNum >= 2 && ex.practiceNum > 0
                    : ''
                "> -->
                  <!-- <span class="section-practice">{{ ex.practiceNum }}</span> -->
                  <!-- /{{ ex.distributeNum }} -->
                <!-- </span> -->
              </div>

            </template>



          </div>
        </div>
      </div>
      <div class="stage">
        <div class="stage-left">
          <div v-for="(item, index) in stage" :key="index">
            <span class="stage-name" :class="[item.className]"></span>
            <span>{{ item.value }}</span>
          </div>
        </div>
        <div class="stage-tips">
          {{ footerText }}
        </div>
        <div v-if="lastDay" class="stage-right">
          <img class="img-tip" src="@/assets/img/writing/tip@3x.png" alt="tip" />
          <span>临近考试啦，请留意返稿所需时间哦！</span>
        </div>
      </div>
    </div>
    <div class="bottom-tips">
      <p>{{ bottomText }}</p>
    </div>
  </div>
</template>

<script>
import http from "@/utils/request";
import PlanCalendarExtra from './PlanCalendarExtra.vue'
import PlanCalendarTitle from './PlanCalendarTitle.vue'


export default {
  props: {
    stay: String,
    type: Number,
    toBookCourse: {
      type: Function,
    },
  },
  components: {
    PlanCalendarExtra,
    PlanCalendarTitle,
  },
  watch: {
    stay: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.lastStay = newVal;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      finish: 1, // 已完成篇数
      total: 20, // 学习计划一共多少篇
      finishStatus: 0, // 当天计划已完成
      allFinishStatus: 0, // 总计划已完成
      planStartDate: "", // 计划开始日期
      currentMonth: 1, // 当前月份
      currentYear: 2020, // 当前年份
      examDate: "", // 考试日
      days: [], // 日历数据
      popClose: true, // 遮罩层
      currentDayValue: 0, // 今天日期
      tipsShow: false, // 遮罩层文案
      events: [], // 活动数据
      params: {}, // 活动查询参数
      lastDay: false, // 是否学习计划最后3天
      topTips: true, // 当前所处阶段是否展示
      desc: "", // 提示文案
      stage: [
        // 每个阶段对应的样式
        {
          className: "first",
          value: "预备期",
        },
        {
          className: "second",
          value: "热身期",
        },
        {
          className: "third",
          value: "进阶期",
        },
        {
          className: "fourth",
          value: "冲刺期",
        },
      ],
      period: "", // 所处阶段
      firPeriod: false, // 预备期
      secPeriod: false, // 热身期
      thPeriod: false, // 进阶期
      fouPeriod: false, // 冲刺期
      bottomText: "", // 鸡汤文
      lastStay: "",
      allSection: 0, // 本周建议预约多少节课
      section: 0, // 本周已完成多少节课
      theWeek: [], // 当周
      footerText: ''
    };
  },
  created() { },
  mounted() {
    if (this.$route.path == '/oral') {
      this.footerText = '计划中口语话题仅供参考，具体上课话题以实际老师上课为准'
    } else {
      this.footerText = ''
    }
    this.popDisplay();
    if (this.lastStay) {
      const year = Number(this.lastStay.slice(0, 4));
      const month = Number(this.lastStay.slice(4));
      this.initDate({
        y: year,
        m: month - 1,
      });
      this.currentYear = year;
      this.currentMonth = month;
      this.getTodayDate();
    } else {
      this.initDate();
      let _now = new Date();
      this.currentYear = _now.getFullYear();
      this.currentMonth = _now.getMonth() + 1;
      this.getTodayDate();
    }
    this.getBottomText();
  },
  methods: {
    isWriting() {
      return this.$route.href.includes('writing')
    },
    // 获取本地时间转换成北京时区
    // PRCDate() {
    //   return new Date(new Date().getTime()+(parseInt(new Date().getTimezoneOffset()/60) + 8)*3600*1000);
    // },
    getTodayDate() {
      let _now = new Date();
      let d = _now.getDate();
      this.currentDayValue = this.formatValue(
        _now.getFullYear(),
        _now.getMonth() + 1,
        d
      );
    },
    async getBottomText() {
      const res = await http(`/hcp/base/base/dict/studyPlanTips`, {
        method: "GET",
      });
      this.bottomText = this.getRandomText(res).key;
    },
    // 随机获取鸡汤文
    getRandomText(arr) {
      let id = this.getRandom(0, arr.length - 1);
      let result = arr[id];
      arr.splice(id, 1);
      return result;
    },
    //生成从minNum到maxNum的随机数
    getRandom(minNum, maxNum) {
      switch (arguments.length) {
        case 1:
          return parseInt(Math.random() * minNum + 1, 10);
          break;
        case 2:
          return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
          break;
        default:
          return 0;
          break;
      }
    },
    formatDate(date) {
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      let day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();

      return `${year}${month}${day}`;
    },
    addDate(date, n) {
      date.setDate(date.getDate() + n);
      return date;
    },
    setDate(date) {
      let dates = [];
      let week = date.getDay() - 1;
      date = this.addDate(date, week * -1);

      for (let i = 0; i < 7; i++) {
        dates.push(this.formatDate(i == 0 ? date : this.addDate(date, 1)));
      }
      this.theWeek = dates;
    },
    // 初始化日期
    async initDate(datoObj) {
      let now = !datoObj ? new Date() : new Date(datoObj.y, datoObj.m, 1);
      // 获取当前时间
      let y = now.getFullYear();
      let m = now.getMonth();
      // let d = now.getDate()
      // 当月最后一天是几号
      let _lastDateOfMonth = new Date(y, m + 1, 0).getDate();
      // 当月第一天是周几
      let _firstDayWeekOfMonth = new Date(y, m, 1).getDay();
      // 上月最后一天是几号
      let _lastDayOfLastMonth = new Date(y, m, 0).getDate();

      let line = 0;
      let temp = [];
      for (let i = 1; i <= _lastDateOfMonth; i++) {
        let _day = new Date(y, m, i).getDay(); // 返回星期几
        // 第一行
        if (_day === 0) {
          temp[line] = [];
          // 如果当月第一天就是周天，取查询参数值
          if (_firstDayWeekOfMonth === 0) {
            this.params.start = this.formatValue(y, m + 1, 1);
          }
        } else if (i === 1) {
          let k = 0;
          temp[line] = [];
          k = _lastDayOfLastMonth - _firstDayWeekOfMonth + 1;
          for (let j = 0; j < _firstDayWeekOfMonth; j++) {
            // 取查询参数值
            if (j === 0) {
              this.params.start = this.formatValue(y, m, k);
            }
            temp[line].push({
              day: k,
              disabled: true,
              value: this.formatValue(y, m, k),
            });
            k++;
          }
        }

        temp[line].push({
          day: i,
          value: this.formatValue(y, m + 1, i),
        });

        // 到周六，并且行数小于5时换行
        if (_day === 6 && line < 5) {
          line++;
          temp[line] = [];
        }
        // 如果到了当月最后一天
        if (i === _lastDateOfMonth) {
          // 下个月的时间补位
          let next = 1;
          let max = line === 5 ? 6 : 13;
          let d = _day;
          // 当最后一天是周六的时候，说明没占位，下个月占位需要为7位
          // 当最后一天不是周六的时候，说明占位了，那么下个月占位需要减1位
          if (_day === 6) {
            d = 0;
            max = 7;
          }
          for (; d < max; d++) {
            // 如果为周六，且行数小于5时候
            if (d === 6 && line < 5) {
              line++;
              temp[line] = [];
            }
            // 取查询参数值
            if (d === max - 1) {
              this.params.end = this.formatValue(y, m + 2, next);
            }
            temp[line].push({
              day: next,
              disabled: true,
              value: this.formatValue(y, m + 2, next),
            });
            next++;
          }
        }
      }
      this.days = temp;
      if (this.$route.query.planId) {
        this.btnTitle = "修改计划";
        const res = await http(
          `/hcp/yida/studyPlan/getStudyPlanDetailsByPlanId/${this.$route.query.planId}`,
          {
            method: "GET",
          }
        );
        this.events = res.appStudyPlanDetailList;
        this.planRange = res.phaseDateRangeVO;
        this.finish = res.practiceNum;
        this.total = res.distributeNum;
        this.allFinishStatus = res.status;
        this.examDate = res.examDate;
        this.planStartDate = res.appStudyPlanDetailList[0].planDate;
      }
      const today = this.getToday(new Date());
      this.setDate(new Date()); // 调用获取当前所在周的数组
      this.allSection = 0;
      this.section = 0;
      this.events.forEach((item) => {
        item.planDate = item.planDate.slice(0, 10).split("-").join("");
        if (item.planDate === today) {
          this.periodColor(item.phase);
          this.period = this.periodSwitch(item.phase);
          this.desc = item.todayPlanDesc;
          this.finishStatus = item.status;
        }
        for (let i = 0; i < this.theWeek.length; i++) {
          if (this.theWeek[i] === item.planDate) {
            this.allSection += item.distributeNum;
            this.section += item.practiceNum;
          }
        }
      });
      this.days.flat().forEach((item) => {
        item = Object.assign(item, this.planRange);
      });
      this.days.flat().forEach((item) => {
        item.value2 = `${item.value.slice(0, 4)}-${item.value.slice(
          4,
          6
        )}-${item.value.slice(6, 10)} 00:00:00`;
      });
      this.days.flat().forEach((item) => {
        if (item.day === 15) {
          item.mock = true; // 控制遮罩层模拟数据显示
        }
        // 控制日历上方所处阶段及文案显示
        if (
          (new Date(item.sprintStartDate) <= new Date() &&
            new Date() <= new Date(item.sprintEndDate)) ||
          (new Date().getMonth() === new Date(item.sprintEndDate).getMonth() &&
            new Date().getDate() === new Date(item.sprintEndDate).getDate())
        ) {
          this.period = "冲刺期";
          this.fouPeriod = true;
        } else if (
          (new Date(item.preparatoryStartDate) <= new Date() &&
            new Date() <= new Date(item.preparatoryEndDate)) ||
          (new Date().getMonth() ===
            new Date(item.preparatoryEndDate).getMonth() &&
            new Date().getDate() ===
            new Date(item.preparatoryEndDate).getDate())
        ) {
          this.period = "预备期";
          this.firPeriod = true;
        } else if (
          (new Date(item.warmStartDate) <= new Date() &&
            new Date() <= new Date(item.warmEndDate)) ||
          (new Date().getMonth() === new Date(item.warmEndDate).getMonth() &&
            new Date().getDate() === new Date(item.warmEndDate).getDate())
        ) {
          this.period = "热身期";
          this.secPeriod = true;
        } else if (
          (new Date(item.advanceStartDate) <= new Date() &&
            new Date() <= new Date(item.advanceEndDate)) ||
          (new Date().getMonth() === new Date(item.advanceEndDate).getMonth() &&
            new Date().getDate() === new Date(item.advanceEndDate).getDate())
        ) {
          this.period = "进阶期";
          this.thPeriod = true;
        } else if (this.type === 0) {
          this.topTips = false;
        }
        // 日历所处阶段对应的背景色显示
        if (
          new Date(item.advanceStartDate) <= new Date(item.value2) &&
          new Date(item.value2) <= new Date(item.advanceEndDate)
        ) {
          item.advance = true;
        }
        if (
          new Date(item.preparatoryStartDate) <= new Date(item.value2) &&
          new Date(item.value2) <= new Date(item.preparatoryEndDate)
        ) {
          item.prepare = true;
        }
        if (
          new Date(item.sprintStartDate) <= new Date(item.value2) &&
          new Date(item.value2) <= new Date(item.sprintEndDate)
        ) {
          item.spring = true;
          if (this.type === 0) {
            // 学习计划最后三天文案显示
            let last = new Date(item.sprintEndDate) - new Date(item.value2);
            if (new Date(last).getDate() <= 3) {
              item.lastThreeDay = true;
            }
            let oldDay = new Date(item.sprintEndDate).getTime();
            let now = new Date().getTime();
            let result = parseInt((oldDay - now) / (1000 * 60 * 60 * 24));
            if ((0 < result && result < 3) || Math.abs(result) === 0) {
              this.lastDay = true;
            } else {
              this.lastDay = false;
            }
          }
        }
        if (
          new Date(item.warmStartDate) <= new Date(item.value2) &&
          new Date(item.value2) <= new Date(item.warmEndDate)
        ) {
          item.warm = true;
        }
      });
    },
    formatValue(y, m, d) {
      if (m > 12) {
        y += 1;
        m = 1;
      } else if (m < 1) {
        y -= 1;
        m = 12;
      }
      let _m = m > 9 ? m : "0" + m;
      let _d = d > 9 ? d : "0" + d;
      return y.toString() + _m + _d;
    },
    // 获取当天日期YY-MM-DD
    getToday(date) {
      let y = date.getFullYear();
      let m = date.getMonth() + 1;
      if (m < 10) {
        m = "0" + m;
      }
      let d = date.getDate();
      if (d < 10) {
        d = "0" + d;
      }
      return `${y}${m}${d}`;
    },
    periodSwitch(data) {
      switch (data) {
        case 1:
          return "预备期";
        case 2:
          return "热身期";
        case 3:
          return "进阶期";
        case 4:
          return "冲刺期";
      }
    },
    periodColor(data) {
      if (data === 1) {
        this.firPeriod = true;
      } else if (data === 2) {
        this.secPeriod = true;
      } else if (data === 3) {
        this.thPeriod = true;
      } else if (data === 4) {
        this.fouPeriod = true;
      }
    },
    // 向前
    pre() {
      this.nextBtn = true;
      this.currentMonth -= 1;
      if (this.currentMonth < 1) {
        this.currentMonth = 12;
        this.currentYear -= 1;
      }
      this.initDate({ y: this.currentYear, m: this.currentMonth - 1 });
    },
    // 向后
    next() {
      this.preBtn = true;
      this.currentMonth += 1;
      if (this.currentMonth > 12) {
        this.currentMonth = 1;
        this.currentYear += 1;
      }
      this.initDate({ y: this.currentYear, m: this.currentMonth - 1 });
    },
    // 我知道了
    handleKnow() {
      this.popClose = false;
      this.days.flat().forEach((item) => {
        if (item.day === 15) {
          item.mock = false;
        }
      });
    },
    handleNoTips() {
      this.tipsShow = !this.tipsShow;
      sessionStorage.setItem("popShow", this.tipsShow);
    },
    toEventDetail(item) {
      if (this.type === 0) {
        this.$router.push({
          path: "/writing",
          query: {
            type: "practice",
            id: item.id,
            date: `${this.currentYear}${this.currentMonth}`,
          },
        });
      } else {
        if (item.questionId) {
          this.$router.push({
            path: "/oral",
            query: {
              type: "detail",
              id: item.id,
              date: `${this.currentYear}${this.currentMonth}`,
            },
          });
        }
      }
    },
    // 遮罩层是否展示，把值存在sessionStorge
    popDisplay() {
      let temp = sessionStorage.getItem("popShow");
      if (temp === "false") {
        this.popClose = true;
      } else if (temp === "true") {
        this.popClose = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.examDate {
  font-size: 14px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold !important;
  color: #3377FF;
  line-height: 19px;
}

.examDateDate {
  color: #3377FF !important;
}

.extra {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(181, 182, 186, 1);
  line-height: 18px;

}

.popContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  height: 100%;
  background-color: rgba(54, 61, 81, 0.6);
  overflow: auto;
}

.bottom-tips {
  padding: 32px 0 210px;
  text-align: center;
  font-size: 12px;
  color: rgba(51, 54, 67, 0.3);
}

.sub-title {
  .firPeriod {
    color: #3377ff;
  }

  .secPeriod {
    color: #58dceb;
  }

  .thPeriod {
    color: #ffbd2b;
  }

  .fouPeriod {
    color: #fe592f;
  }
}

.sub_line {
  text-decoration: line-through;
  color: #8e95a3;
}

.calendar_box {
  width: 100%;
  margin-top: 20px;
  padding: 32px 15px 64px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.05);
  font-family: -apple-system, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "WenQuanYi Micro Hei", "Microsoft Yahei", sans-serif;
  font-size: 14px;
  color: #333;
  background-color: #fff;
  border-radius: 6px;
  -webkit-font-smoothing: antialiased;

  .calendar_month {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49px;
    line-height: 49px;
    font-size: 20px;
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-weight: bold;
    color: #333643;
    margin-bottom: 30px;

    span {
      margin: 0 16px;
      vertical-align: middle;
    }

    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .calendar_week {
    display: flex;
    padding: 5px 0;
    margin: 1px 0;

    .cal-cell1 {
      flex: 1;
      font-weight: bolder;
      text-align: center;
    }
  }

  .calendar_week_days {
    display: flex;

    .cal_month_day {
      flex: 1;
      height: 76px;
      text-align: center;
      /* cursor: pointer; */
      margin: 0 2px 2px 0;
      padding-top: 16px;
      position: relative;

      .topic-name {
        display: flex;
        justify-content: space-evenly;

        .img-finish {
          position: absolute;
          bottom: 4px;
          right: 4px;
          width: 8px;
          height: 8px;
        }

        .section-finish {
          position: absolute;
          right: 4px;
          bottom: 0px;
          font-size: 9px;
          font-family: MicrosoftYaHei;
          color: #b5b6ba;
          transform: scale(0.75);

          .section-practice {
            font-size: inherit;
            color: #2fc049;
          }
        }
      }

      .topic-name-area::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      span {
        display: inline-block;
        font-size: 16px;
        line-height: 30px;
        color: #333643;
        font-weight: bold;
        font-family: Barlow-Bold, Barlow;
        transition: all 0.3s ease-in-out;
        -webkit-transition: all 0.1s ease-in-out;

        &.disabled {
          opacity: 0.25;
        }

        &.red {
          color: #d0021b;
        }

        &.active {
          position: absolute;
          top: 11px;
          left: 50%;
          transform: translateX(-50%);

          width: 28px;
          height: 28px;
          text-align: center;
          line-height: 28px;
          border-radius: 50%;

          background: #3377ff;
          font-size: 10px;
          font-weight: bold;
          color: #ffffff;
        }
      }

      .lastThree {
        color: #fe592f !important;
      }

      p {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #0096ff;
      }

      .btn-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 2001;

        .selected-btn {
          transform: translateY(108px);
          width: 94px;
          height: 36px;
          background: #3377ff;
          border-radius: 16px;
          z-index: 2001;
          text-align: center;
          line-height: 36px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          cursor: pointer;
        }

        .close-btn {
          transform: translateY(120px);
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
          margin-left: 8px;
        }

        .img-pcdianji {
          transform: translateY(-54px);
          text-align: center;
          width: 40px;
          height: 40px;
        }

        .text-pcdianji {
          position: absolute;
          top: 122px;
          left: 0px;
          white-space: nowrap;
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: #ffffff;
        }

        .img-btn_xz {
          transform: translate(-30px, 66px);
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  .advance {
    background: #fff5dd;
  }

  .prepare {
    background: #dff2ff;
  }

  .spring {
    background: #ffe2db;
  }

  .warm {
    background: #e9feff;
  }

  .stage {
    display: flex;
    align-items: center;
    margin-top: 23px;
    flex-direction: column;

    .stage-left {
      display: flex;

      :not(:last-child) {
        margin-right: 40px;
      }

      .stage-name {
        margin-right: 8px;
      }
    }

    .stage-tips {
      margin-top: 25px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #eb5e00;
      line-height: 19px;
    }

    .stage-right {
      transform: translateY(30px);
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #ee6a5b;

      .img-tip {
        width: 16px;
        height: 16px;
      }
    }

    .first {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #3377ff;
      border-radius: 3px;
      opacity: 0.39;
      margin-right: 8px;
    }

    .second {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #58dceb;
      border-radius: 3px;
      opacity: 0.39;
      margin-right: 8px;
    }

    .third {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #ffbd2b;
      border-radius: 3px;
      margin-right: 8px;
      opacity: 0.39;
    }

    .fourth {
      display: inline-block;
      width: 10px;
      height: 10px;
      background: #fe592f;
      border-radius: 3px;
      opacity: 0.39;
      margin-right: 8px;
    }
  }
}
</style>