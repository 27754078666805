<template>
  <div v-if="topTips" class="wrapper">
    <template v-if="type === 1">
      根据您当前阶段本周建议预约 <span class="bold">{{ total }} 节口语1V1课程</span>。已完成<span class="bold">{{
        finish
      }}</span
      >/{{ total }}节
    </template>
    <template v-if="type === 0">
      根据您当前阶段本周建议提交 <span class="bold">{{ total }} 篇作文</span>。已完成<span class="bold">{{
        finish
      }}</span
      >/{{ total }} 篇
    </template>

    <!-- <p style="color: #8e95a3; font-size: 12px; margin-bottom: 10px">
      已完成
      <span style="color: #3377ff">{{ finish }}</span>
      /{{ total }}{{ type === 0 ? "篇" : "节" }}
    </p>
    <p class="sub-title" style="font-size: 14px; font-weight: bold">
      当前处在
      <span :class="{
        firPeriod: firPeriod,
        secPeriod: secPeriod,
        thPeriod: thPeriod,
        fouPeriod: fouPeriod,
      }" style="font-size: 16px">{{ period }}</span>
      <span v-if="type === 0" :class="{
        sub_line: finishStatus || allFinishStatus,
      }">{{ desc ? "，" + desc : "" }}</span>
      <span v-else-if="type === 1" :class="{
        sub_line: allSection === section && allSection !== 0,
      }">{{ ` 建议本周预约${allSection}节课，已完成${section}节` }}</span>
    </p> -->
  </div>
</template>

<script>
export default {
  props: {
    topTips: Boolean,
    finish: String,
    total: Number,
    type: Number,
    firPeriod: Boolean,
    secPeriod: Boolean,
    thPeriod: Boolean,
    fouPeriod: Boolean,
    period: String,
    finishStatus: Boolean,
    allFinishStatus: Boolean,
    desc: String,
    allSection: Number,
    section: Number
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.wrapper {
  margin-bottom: 34px;

  height: 37px;
  background: #f2fff4;
  border-radius: 12px;
  border: 1px solid rgba(47, 192, 73, 0.29);

  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #4e596f;
  line-height: 35px;

  padding: 0 16px;
  .bold {
    color: rgba(47, 192, 73, 1);
  }
}
</style>
