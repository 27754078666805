<template>
  <el-dialog
    v-model="failDialogVisible"
    title="设置失败"
    :before-close="handleClose"
    custom-class="fail-container"
    width="343px"
    center
    :show-close="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="content">
      <span class="normal">你的备考时间已非常紧迫，</span>
      <span class="online">建议咨询小助手为你安排练习计划</span>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleClose" class="btn"
          >知道了</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  emits: ["failDialog"],
  props: {
    visible: Boolean,
    type: Number,
  },
  data() {
    return {
      failDialogVisible: false,
    };
  },
  watch: {
    visible: function (newVal, oldVal) {
      this.failDialogVisible = newVal;
    },
  },
  methods: {
    handleClose() {
      this.failDialogVisible = false;
      this.$emit("failDialog", this.failDialogVisible);
      let path = "";
      if (this.type === 0) {
        path = "/writing";
      } else if (this.type === 1) {
        path = "/oral";
      }
      this.$router.push({
        path: path,
        query: { type: "failback" },
      });
    },
  },
};
</script>

<style lang="scss">
.el-button--text {
  margin-right: 15px;
}
.el-select {
  width: 338px;
  height: 40px;
}
.fail-container {
  border-radius: 16px;
  height: 240px;
  margin-top: 25vh !important;
  .content {
    text-align: center;
    font-size: 14px;
    .normal {
      color: #333643;
    }
    .online {
      color: #ee6a5b;
    }
  }
  .el-dialog__header {
    padding-bottom: 25px;
  }
  .el-dialog__title {
    font-size: 20px;
    font-weight: bold;
    color: #333643;
  }
  .el-form-item {
    margin-bottom: 24px;
  }
  .el-form-item__label {
    font-size: 14px;
    font-weight: bold;
    color: #131b33;
  }
  .dialog-footer {
    float: right;
    margin-right: 90px;
  }
  .btn {
    width: 94px;
    height: 36px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: bold;
  }
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
.year-select {
  width: 102px;
}
.date-select {
  width: 102px;
  &:first-child {
    margin-left: 16px;
  }
}
</style>