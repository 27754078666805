<template>
  <div class="practice-container">
    <div v-if="type === 'oral'" class="oral-containter">
      <div v-for="(item, index) in data" :key="index" class="practice-card practice-card-oral">
        <div class="practice-text-area">
          <div ref="practice_text" class="practice-text" :class="{
            hide: displayArray[index] ? displayArray[index].isHide : '',
          }">
            <p v-html="item.question" class="resetStyle"></p>
            <span v-show="displayArray[index] ? displayArray[index].isHide : ''" @click="show(index)"
              class="text-btn">展示</span>
            <span v-show="displayArray[index] ? displayArray[index].isShow : ''" @click="hide(index)"
              class="text-btn">收起</span>
          </div>
        </div>
        <div class="practice-bottom">
          <div class="practice-tags">
            <p>
              {{ item.topicName }}
            </p>
          </div>
          <div>
            <button class="practice-btn" @click="handleBookCourseEnhance(item?.oralTopicId)" v-if="!item?.ifPracticed">
              去约课
            </button>
            <div v-else class="practice-btn practice-btn-finish">
              去约课
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-card v-for="(item, index) in data" :key="index" class="practice-card">
        <div class="practice-text-area">
          <div ref="practice_text" class="practice-text" :class="{
            hide: displayArray[index] ? displayArray[index].isHide : '',
          }">
            {{ item.title }}
            <span v-show="displayArray[index] ? displayArray[index].isHide : ''" @click="show(index)"
              class="text-btn">展示</span>
            <span v-show="displayArray[index] ? displayArray[index].isShow : ''" @click="hide(index)"
              class="text-btn">收起</span>
          </div>
        </div>
        <upload-file :imgUrl="item.imageUrl && item.imageUrl.join(',')" folder-code="40" :limit="5"
          :type="`practice`" />
        <div class="practice-bottom">
          <div class="practice-tags">
            <p>
              {{ item.writingPart === 0 ? "大作文" : "小作文" }}/{{
                  item.topicName
              }}
            </p>
          </div>
          <div>
            <button class="practice-btn" @click="toPractice(item.writingId, $route.query.id)">
              去练习
              <span v-show="!item.practiceStatus" class="red-point"></span>
            </button>
          </div>
        </div>
      </el-card>
    </div>
    <div v-if="type === 'oral'" class="right-tips">
      计划中口语话题仅供参考，具体上课话题以实际老师上课为准
    </div>
    <div v-else style="position: absolute; top: 0; right: 0">
      当前剩余{{ num }}次
    </div>
  </div>
</template>

<script>
import UploadFile from "../views/writing/components/UploadFile.vue";
import { connectState } from "@/utils/wyUtil";
import http from "@/utils/request";


export default {
  components: { UploadFile },
  props: {
    data: Array,
    num: Number,
    type: String,
    handleBookCourse: Function,
  },
  data() {
    return {
      displayArray: [], // 对应作文题目展示
    };
  },
  watch: {
    data: function (newVal, oldVal) {
      this.$nextTick(() => {
        if (this.$refs.practice_text.clientHeight > 121) {
          for (let i = 0; i < newVal.length; i++) {
            this.displayArray.push({
              isHide: true,
              isShow: false,
            });
          }
        }
      });
    },
  },
  computed: {
    ...connectState("oral", ["oralCount"]),
  },
  methods: {
    handleBookCourseEnhance(oralTopicId) {
 
      this.handleBookCourse(oralTopicId)
    },
    toPractice(item, pid) {
      this.$emit("goDetail", { id: item, pid: pid, count: this.num })
      // this.$router.push({
      //   path: `/submitArticle`,
      //   query: { id: item, pid: pid, count: this.num },
      // });
    },
    // handleBookCourse() {
    //   if (this.oralCount <= 0) {
    //     this.$router.push("/oral");
    //   } else {
    //     this.$router.push("/bookCourse");
    //   }
    //   return true;
    // },
    show(ids) {
      // 作文题目展示
      this.displayArray[ids].isHide = false;
      this.displayArray[ids].isShow = true;
    },
    hide(ids) {
      // 作文题目收起
      this.displayArray[ids].isHide = true;
      this.displayArray[ids].isShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.resetStyle::v-deep,
.resetStyle::v-deep * {
  font-size: 14px !important;
  font-family: MicrosoftYaHei !important;
  color: #757B87 !important;
  font-weight: 400 !important;
  line-height: 19px !important;

  /* * {
    font-size: 14px !important;
    font-family: MicrosoftYaHei !important;
    color: #757B87 !important;
    font-weight: 400 !important;
    line-height: 19px !important;
  } */

  /* p {
    font-size: 14px;
    font-family: MicrosoftYaHei;

  }
  span{
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #757B87;
    line-height: 19px;
  } */

}


.oral-containter {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  row-gap: 16px;
  grid-template-rows: masonry;
}

.practice-container {

  .practice-card {
    width: 467px;
    background: #ffffff;
    border-radius: 16px;
    margin-top: 16px;

    border-radius: 16px;
    padding: 16px 10px;



    .practice-text-area {
      background: #f9f9fa;
      border-radius: 12px;

      .hide {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
        height: 116px;
      }

      .practice-text {
        position: relative;
        width: 423px;
        line-height: 21px;
        padding: 8px 12px 8px 10px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #757b87;
        z-index: 1;

        .text-btn {
          position: absolute;
          right: 2px;
          bottom: 2px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: #3377ff;
          z-index: 2;
          cursor: pointer;
        }
      }
    }

    .practice-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 8px;

      .practice-tags {
        // width: 71px;
        height: 16px;
        background: rgba(117, 123, 135, 0.13);
        border-radius: 8px;

        p {
          font-size: 10px;
          color: #8e95a3;
          transform: scale(0.8);
        }
      }

      .practice-btn {
        position: relative;
        width: 64px;
        height: 28px;
        border-radius: 11px;
        border: 1px solid rgba(51, 119, 255, 1);
        background: #fff;
        font-size: 12px;
        color: #3377ff;
        font-weight: bold;

        &.practice-btn-finish {
          border: 1px solid rgba(200, 200, 200, 1);
          color: rgba(200, 200, 200, 1);

          &:hover {
            cursor: default;
            background: transparent;
          }
        }

        text-align: center;
        line-height: 26px;

        &:hover {
          cursor: pointer;
          background: rgb(248, 245, 245);
        }

        .red-point {
          display: inline-block;
          position: absolute;
          right: 0;
          top: 0;
          width: 7px;
          height: 7px;
          background: #ee6a5b;
          border-radius: 50%;
        }
      }
    }
  }

  .practice-card-oral {
    margin-top: 0;
  }

  .right-tips {
    position: absolute;
    top: 21px;
    right: 0;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #eb5e00;
    line-height: 19px;
  }
}
</style>