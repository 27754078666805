<template>
  <template v-if="planDate == value">
    <template v-if="practiceNum > 0">
      <div v-if="distributeNum > 1" class="extra">
        <span>{{ practiceNum }}</span>/{{ distributeNum }}节
      </div>
      <div v-else-if="distributeNum === 1">
        <img src="@/assets/img/oralStage/checkIt@2x.png" class="checkItIcon" />
      </div>
    </template>
    <template v-else>
      <div class="extra">
        {{ distributeNum }}节
      </div>
    </template>

  </template>
</template>

<script>

export default {
  name: 'PlanCalendarExtra',
  props: {
    topicNameList: Array,
    planDate: String,
    value: String,
    practiceNum: Number,
    distributeNum: Number,
  },
  computed: {
    totalNum() {
      return (this.topicNameList || []).reduce((last, e) => {
        return this.planDate == this.value ? (last + 1) : last
      }, 0)
    },

  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.checkItIcon {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 50%;
  bottom: 12px;
  transform: translateX(-50%);
}

.extra {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(181, 182, 186, 1);

  span {
    color: #2FC049;
  }
}
</style>