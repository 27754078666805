<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="type === 0 ? '写作学习计划' : '口语陪练计划'"
    :before-close="handleClose"
    custom-class="plan-container"
    width="562px"
    center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
  >
    <div class="banner">
      <img src="@/assets/img/writing/banner@3x.png" alt="banner" />
    </div>
    <el-form :model="form" :rules="rules" ref="ruleFormRef">
      <div style="display: flex">
        <el-form-item label="考试日期" :label-width="formLabelWidth" prop="examDate">
          <el-date-picker
            v-model="form.examDate"
            type="date"
            placeholder="选择日期"
            style="width: 338px"
            :disabledDate="disabledDate"
          ></el-date-picker>
        </el-form-item>
      </div>
      <el-form-item label="上次成绩" :label-width="formLabelWidth" prop="grade">
        <el-select v-model="form.grade" placeholder="——">
          <el-option
            v-for="item in gradeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="目标分数" :label-width="formLabelWidth" prop="score">
        <el-select v-model="form.score" placeholder="——">
          <el-option
            v-for="item in scoreOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="type === 0" label="考试类别" :label-width="formLabelWidth" prop="examType">
        <el-select v-model="form.examType" placeholder="——">
          <el-option
            v-for="item in typeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose" class="btn">取消</el-button>
        <el-button type="primary" @click="handleSave" class="btn">确定</el-button>
      </span>
    </template>
  </el-dialog>
  <fail-dialog :type="type" :visible="failVisible" @failDialog="fail"></fail-dialog>
  <success-dialog
    :visible="successVisible"
    @successDialog="success"
    :id="pid"
    :num="pnum"
    :type="type"
  ></success-dialog>
  <el-dialog
    v-model="confirmVisible"
    title="提示"
    :before-close="confirmClose"
    custom-class="confirm-container"
    width="343px"
    center
    :close-on-press-escape="false"
  >
    <div class="content">
      <span class="normal">重新生成计划表将会覆盖原有的计划，确定保存吗？</span>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button class="btn" @click="confirmClose">取消</el-button>
        <el-button type="primary" class="btn" @click="confirmSave">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>


<script>
import failDialog from "./failDialog.vue";
import successDialog from "./successDialog.vue";
import http from "@/utils/request";
import dayjs from 'dayjs'

export default {
  emits: ["planDialog"],
  components: {
    successDialog,
    failDialog,
  },
  props: {
    visible: Boolean,
    planData: Object,
    type: Number,
  },
  data() {
    return {
      dialogFormVisible: false,
      failVisible: false,
      successVisible: false,
      pid: null,
      pnum: null,
      confirmVisible: false,
      formLabelWidth: "100px",
      form: {
        examDate: null,
        grade: null,
        score: null,
        examType: null,
        type: 0,
        planId: null,
        userId: null,
        wxNickName: null,
      },
      rules: {
        examDate: [
          {
            required: true,
            message: "请选择日期",
            trigger: "change",
          },
        ],
        grade: [
          {
            required: false,
            trigger: "change",
          },
        ],
        score: [
          {
            required: true,
            message: "请选择目标分数",
            trigger: "change",
          },
        ],
        examType: [
          {
            required: true,
            message: "请选择考试类别",
            trigger: "change",
          },
        ],
      },
      gradeOptions: [
        {
          value: "4",
          label: "4",
        },
        {
          value: "4.5",
          label: "4.5",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "5.5",
          label: "5.5",
        },
        {
          value: "6",
          label: "6",
        },
        {
          value: "6.5",
          label: "6.5",
        },
        {
          value: "7",
          label: "7",
        },
        {
          value: "7.5",
          label: "7.5",
        },
        {
          value: "8",
          label: "8",
        },
        {
          value: "8.5",
          label: "8.5",
        },
        {
          value: "9",
          label: "9",
        },
      ],

      scoreOptions: [
        {
          value: "4",
          label: "4",
        },
        {
          value: "4.5",
          label: "4.5",
        },
        {
          value: "5",
          label: "5",
        },
        {
          value: "5.5",
          label: "5.5",
        },
        {
          value: "6",
          label: "6",
        },
        {
          value: "6.5",
          label: "6.5",
        },
        {
          value: "7",
          label: "7",
        },
        {
          value: "7.5",
          label: "7.5",
        },
        {
          value: "8",
          label: "8",
        },
        {
          value: "8.5",
          label: "8.5",
        },
        {
          value: "9",
          label: "9",
        },
      ],
      typeOptions: [
        {
          value: 0,
          label: "A类（雅思学术类）",
        },
        {
          value: 1,
          label: "G类（雅思培训类）",
        },
      ],
    };
  },
  created() { },
  computed: {},
  watch: {
    visible: function (newVal, oldVal) {
      this.dialogFormVisible = newVal;
    },
    planData: function (newVal, oldVal) {
      this.form.examDate = newVal.examDate;
      this.form.grade = newVal.lastScore;
      this.form.score = newVal.targetScore;
      this.form.examType = newVal.examType;
      this.form.planId = newVal.planId;
      this.form.userId = newVal.userId;
      this.form.wxNickName = newVal.wxNickName;
    },
  },
  methods: {
    disabledDate(time) {
      return (
        time.getTime() < Date.now() - 24 * 3600000 ||
        time.getTime() > Date.now() + 120 * 24 * 3600000
      );
    },

    fail(val) {
      this.failVisible = val;
    },

    success(val) {
      this.successVisible = val;
    },

    handleClose() {
      this.dialogFormVisible = false;
      this.$emit("planDialog", this.dialogFormVisible);
      this.$refs["ruleFormRef"].resetFields();
    },

    confirmClose() {
      this.confirmVisible = false;
    },

    confirmSave() {
      this.successVisible = true; // 设置成功弹窗显示
    },

    dataFormat(val) {
      const date = dayjs(val).format('YYYY/MM/DD')
      let year = new Date(date).getFullYear();
      let month = new Date(date).getMonth() + 1;
      let day = new Date(date).getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return `${year}-${month}-${day} 00:00:00`;
    },
    handleSave() {
      this.$refs["ruleFormRef"].validate(async (valid) => {
        if (valid) {
          let data = {
            examDate: this.dataFormat(this.form.examDate),
            examType: this.form.examType,
            lastScore: this.form.grade,
            targetScore: this.form.score,
            type: this.type,
            planId: this.form.planId,
            userId: this.form.userId,
            wxNickName: this.form.wxNickName,
          };
          const res = await http("/hcp/yida/studyPlan/createStudyPlan", {
            method: "POST",
            data,
          });
          if (!res.result) {
            this.failVisible = true; // 设置失败弹窗显示
          } else {
            this.pid = res.planId;
            this.pnum = res.phaseNum;
            if (data.planId) {
              this.confirmVisible = true;
            } else {
              this.successVisible = true; // 设置成功弹窗显示
            }
          }
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.el-button--text {
  margin-right: 15px;
}
.el-select {
  width: 338px;
  height: 40px;
}
.plan-container {
  border-radius: 24px;
  height: 613px;
  .banner {
    img {
      width: 420px;
      height: 140px;
      margin-left: 50px;
      margin-bottom: 24px;
    }
  }
  .el-dialog__header {
    padding-bottom: 0px;
  }
  .el-dialog__title {
    font-size: 20px;
    font-weight: bold;
    color: #131b33;
  }
  .el-form-item {
    margin-bottom: 24px;
  }
  .el-form-item__label {
    font-size: 14px;
    font-weight: bold;
    color: #131b33;
  }
  .dialog-footer {
    float: right;
    margin-right: 72px;
  }
  .btn {
    width: 118px;
    height: 42px;
    border-radius: 13px;
    font-size: 14px;
    font-weight: bold;
  }
}
.confirm-container {
  width: 343px;
  // height: 212px;
  margin-top: 25vh !important;
  background: #ffffff;
  border-radius: 16px;
  .btn {
    width: 94px;
    height: 36px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: bold;
  }
}
.dialog-footer button:first-child {
  margin-right: 10px;
}
.year-select {
  width: 102px;
}
.date-select {
  width: 102px;
  &:first-child {
    margin-left: 16px;
  }
}
</style>