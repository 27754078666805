<template>
  <el-dialog v-model="successDialogVisible" title="设置成功" :before-close="handleClose" custom-class="success-container"
    width="343px" center :show-close="false" :close-on-press-escape="false" :close-on-click-modal="false">
    <div class="content">
      <p v-if="isFirstLine" class="normal">你的备考时间较为紧迫，系统为你生成了</p>
      <p v-if="isSecondLine" class="normal">你的备考时间有点紧凑，系统为你生成了</p>
      <p v-if="isThirdLine" class="normal">你的备考时间比较充裕，系统为你生成了</p>
      <p v-if="isFourthLine" class="normal">你的备考时间非常充足，系统为你生成了</p>
      <div class="step">
        <span :class="{ fourth: isFourth }">预备</span>
        <span :class="{ third: isThird }">热身</span>
        <span :class="{ second: isSecond }">进阶</span>
        <span :class="{ first: isFirst }">冲刺</span>
      </div>
      <span v-if="isFirstLine" class="firstLine"></span>
      <span v-if="isSecondLine" class="secondLine"></span>
      <span v-if="isThirdLine" class="thirdLine"></span>
      <span v-if="isFourthLine" class="fourthLine"></span>
      <p class="normal">{{ num }}个阶段的{{ type === 0 ? "练习" : "约课" }}计划表</p>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose" class="btn">不用了</el-button>
        <el-button type="primary" @click="jump" class="btn">去看看</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  emits: ["successDialog"],
  props: {
    visible: Boolean,
    id: String,
    num: Number,
    type: Number,
  },
  data() {
    return {
      successDialogVisible: false,
      isFirst: false,
      isSecond: false,
      isThird: false,
      isFourth: false,
      isFirstLine: false,
      isSecondLine: false,
      isThirdLine: false,
      isFourthLine: false,
      planId: "",
    };
  },
  watch: {
    visible: function (newVal, oldVal) {
      this.successDialogVisible = newVal;
    },
    num: function (newVal, oldVal) {
      console.log(newVal);
      if (newVal === 1) {
        this.isFirst = true;
        this.isFirstLine = true;
      } else if (newVal === 2) {
        console.log("123");
        this.isFirst = true;
        this.isSecond = true;
        this.isSecondLine = true;
      } else if (newVal === 3) {
        this.isFirst = true;
        this.isSecond = true;
        this.isThird = true;
        this.isThirdLine = true;
      } else if (newVal === 4) {
        this.isFirst = true;
        this.isSecond = true;
        this.isThird = true;
        this.isFourth = true;
        this.isFourthLine = true;
      }
    },
    id: function (newVal, oldVal) {
      this.planId = newVal;
    },
  },
  methods: {
    handleClose() {
      this.successDialogVisible = false;
      this.$emit("successDialog", this.successDialogVisible);
      let path = "";
      if (this.type === 0) {
        path = "/writing";
      } else if (this.type === 1) {
        path = "/oral";
      }
      this.$router.push({
        path: path,
        query: { type: "successBack" },
      });
    },
    jump() {
      let path = "";
      if (this.type === 0) {
        path = "/writing";
        this.$router.push({
          path: path,
          query: { type: "plan", planId: this.planId },
        });

        return
      } else if (this.type === 1) {
        path = "/oral";
        this.$router.push({
          path: "/oral",
          query: { type: "stage",  planId: this.planId },
        });
        return 
      }
    },
  },
};
</script>

<style lang="scss">
.el-button--text {
  margin-right: 15px;
}

.el-select {
  width: 338px;
  height: 40px;
}

.success-container {
  border-radius: 16px;
  height: 304px;
  margin-top: 25vh !important;

  .content {
    text-align: center;
    font-size: 14px;

    .normal {
      color: #333643;

      &:last-child {
        margin-top: 14px;
      }
    }

    .online {
      color: #ee6a5b;
    }

    .step {
      display: flex;
      justify-content: space-between;
      color: #b5b6ba;
      margin: 7px 35px 0;

      .first {
        color: #fe592f;
      }

      .second {
        color: #ffbd2b;
      }

      .third {
        color: #58dceb;
      }

      .fourth {
        color: #3377ff;
      }
    }

    .firstLine {
      display: inline-block;
      width: 240px;
      height: 4px;
      transform: translateY(-5px);
      border-radius: 4px;
      background: linear-gradient(90deg,
          #ffffff 0%,
          #d9dcde 30%,
          #dadbdd 61%,
          #fe6e41 86%,
          #fe582f 100%);
    }

    .secondLine {
      display: inline-block;
      width: 240px;
      height: 4px;
      transform: translateY(-5px);
      border-radius: 4px;
      background: linear-gradient(90deg,
          #ffffff 0%,
          #d9dcde 30%,
          #ffbd2b 61%,
          #fe6e41 86%,
          #fe582f 100%);
    }

    .thirdLine {
      display: inline-block;
      width: 240px;
      height: 4px;
      transform: translateY(-5px);
      border-radius: 4px;
      background: linear-gradient(93deg,
          #ffffff 0%,
          #58dceb 28%,
          #ffbd2b 57%,
          #fe6e41 86%,
          #fe582f 100%);
    }

    .fourthLine {
      display: inline-block;
      width: 240px;
      height: 4px;
      transform: translateY(-5px);
      border-radius: 4px;
      background: linear-gradient(90deg,
          #3377ff 0%,
          #70ebf2 30%,
          #ffd15d 61%,
          #fe6e41 86%,
          #fe582f 100%);
    }
  }

  .el-dialog__header {
    padding-bottom: 8px;
  }

  .el-dialog__title {
    font-size: 20px;
    font-weight: bold;
    color: #333643;
  }

  .el-form-item {
    margin-bottom: 24px;
  }

  .el-form-item__label {
    font-size: 14px;
    font-weight: bold;
    color: #131b33;
  }

  .dialog-footer {
    float: right;
    margin-right: 44px;
    margin-top: 10px;
  }

  .btn {
    width: 94px;
    height: 36px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: bold;
  }
}

.dialog-footer button:first-child {
  margin-right: 10px;
}

.year-select {
  width: 102px;
}

.date-select {
  width: 102px;

  &:first-child {
    margin-left: 16px;
  }
}
</style>