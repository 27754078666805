<template>
  <div class="uploadFile">
    <div
      v-show="imgList.length"
      v-for="(item, i) in imgList"
      :key="i"
      class="upload-img"
      :style="{
        width: type === 'practice' ? '50px' : '',
        height: type === 'practice' ? '50px' : '',
      }"
      @mouseenter="isMask[i] = true"
      @mouseleave="isMask[i] = false"
    >
      <img :src="item" alt="" />
      <div class="mask" v-if="isMask[i]">
        <i
          @click="preview(item)"
          class="el-icon-zoom-in"
          :style="{
            marginRight:
              type === 'practice' || type === 'practiceSubmit' ? '0px' : '',
          }"
        ></i>
        <i
          v-if="type !== 'practice' && type !== 'practiceSubmit'"
          @click="delImg(i)"
          class="el-icon-delete"
        ></i>
      </div>
    </div>
    <el-upload
      v-if="imgList.length < limit"
      class="avatar-uploader"
      action="/hcp/user/file/uploadFile"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :data="{ folderCode: folderCode }"
      :on-success="onSuccess"
      :limit="limit"
    >
      <i
        v-if="type !== 'practice' && type !== 'practiceSubmit'"
        class="el-icon-plus avatar-uploader-icon"
      ></i>
      <template #tip>
        <div
          v-if="type !== 'practice' && type !== 'practiceSubmit'"
          class="el-upload__tip"
        >
          支持PNG,JPG,大小不超过5M
        </div>
      </template>
    </el-upload>
  </div>
  <preview-img ref="previewImg" :cur-img="curImg" />
</template>

<script>
import PreviewImg from "./PreviewImg.vue";

export default {
  components: { PreviewImg },
  props: {
    folderCode: String,
    imgUrl: String,
    limit: {
      type: Number,
      default: 1,
    },
    type: String,
  },
  data() {
    return {
      isMask: [],
      imgList: [],
      curImg: "",
    };
  },
  watch: {
    // imgUrl(val) {
    //   if (val) {
    //     this.imgList = val.split(',');
    //   } else {
    //     this.imgList = [];
    //   }
    // },
    imgUrl: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.imgList = newVal.split(",");
        } else {
          this.imgList = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    beforeUpload(file) {
      const isIMG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isIMG) {
        this.$message.error("上传文件只支持PNG，JPG格式!");
      }
      if (!isLt5M) {
        this.$message.error("上传文件大小不能超过5MB!");
      }
      return isIMG && isLt5M;
    },
    onSuccess(res) {
      this.imgList.push(res.content);
      const url = this.imgList.join(",");
      this.$emit("onSuccess", url);
    },
    preview(url) {
      this.curImg = url;
      this.$refs.previewImg.init();
    },
    delImg(i) {
      this.imgList.splice(i, 1);
      const url = this.imgList.join(",");
      this.$emit("onSuccess", url);
    },
  },
};
</script>

<style lang="scss" scoped>
.uploadFile {
  margin-top: 15px;
  display: flex;
  align-items: center;
  .upload-img {
    margin-right: 20px;
    height: 100px;
    width: 100px;
    position: relative;
    img {
      height: 100%;
      width: 100%;
    }
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        cursor: pointer;
        &.el-icon-zoom-in {
          margin-right: 10px;
        }
      }
    }
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #cacdd6;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px dashed #cacdd6;
    border-radius: 9px;
    &:hover {
      border-color: #3377ff;
    }
  }
  .el-upload__tip {
    font-size: 14px;
  }
}
</style>
